.static-page {
    height: 100%;
}

.leadership-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (orientation: portrait) {
    .leadership-video {
        width: 60vw;
        height: 25vh;
        margin: 1vh;
    }

    .leadership-post {
        width: 50vw;
        height: 40vh;
        margin: 1vh;
    }

    .leadership-card-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5vw;
        padding: 2vw;
    }

    .leadership-card {
        font-size: 2.2vw;
    }

    .leadership-card-body {
        display: flex;
        flex-direction: column;
        gap: 3vw;
        padding: 5vw;
        width: 75vw;
        align-items: left;
        justify-content: left;
    }
}

@media only screen and (orientation: landscape) {
    .leadership-video {
        width: 50vw;
        height: 50vh;
        margin: 1vh;
    }

    .leadership-post {
        width: 30vw;
        height: 52vh;
        margin: 1vh;
    }

    .leadership-card-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5vw;
        padding: 2vw;
    }

    .leadership-card {
        font-size: 1.2vw;
    }

    .leadership-card-body {
        display: flex;
        flex-direction: column;
        gap: 3vw;
        padding: 2vw;
        width: 50vw;
        align-items: left;
        justify-content: left;
    }
}

.leadership-img {
    position: absolute;
    width: 100vw;
    height: 420vh;
    top: 30vh;
    background-image: url("/src/img/spring_bbq.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.husky100-img {
    background-image: url("/src/img/husky100.jpg");
    background-size: cover;
    width: 100%;
    height: 35vh;
}



.leadership-page a {
    color: var(--primary);
    font-weight: bold;
}

.leadership-header {
    text-align: center;
    margin: 0;
}

.leadership-date {
    text-align: center;
    font-style: italic;
    font-weight: lighter;
    margin-bottom: 0;
}

.leadership-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    padding: 2vw;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 2.5vh;
}

.leadership-card p, .leadership-card ul{
    margin: 0;
}