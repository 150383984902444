.main-page {
    justify-content: center;
    align-items: center;
}

.main {
    display: flex;
    flex-direction: row;
    width: 70vw;
    background-color: white;
}

.main-bg {
    /* background-image: url('/src/img/lines.jpeg'); */
    background-color: white;
    padding: 1vw;
    background-size: 30%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

@media only screen and (orientation: portrait) {
    .main-photo-container {
        width: 70vw;
        height: 30vh;
        margin:0;
        padding:0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main-photo {
        background-image: url("/src/img/portrait.jpg");
        background-position: top;
        background-size: cover;
        width: inherit;
        height: inherit;
        position: absolute;
    }

    .main {
        flex-direction: column-reverse;
    }

    .intro {
        width: 60vw;
        height: inherit;
        margin: 5vmin 5vmin 2vmin 5vmin;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .main-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .card {
        position: relative;
        background-color: white;
        width: 18vh;
        height: 25vh;
        margin: 2vh 0;
        padding: 0;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;

        border-color: var(--primary);
        border-style: solid;
        border-width: 5px;
    }

    .hello {
        font-size: 8vmin;
        margin: 0;
        font-weight: bold;
    }

    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        border-color: var(--primary);
        border-width: 1.5px;
        height: 7vmin;
        width: 25vmin;
        padding: 1vh;
        font-size: 3vmin;
    }

    .card-socials a img{
        width: 3vh;
    }

    .card-footer {
        background-color: var(--primary);
        position: absolute;
        width: inherit;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 4vh;
        bottom: 0;
    }

    .intro p {
        font-size: 2.5vw;
    }
}

@media only screen and (orientation: landscape) {
    .main-photo-container {
        width: 30vw;
        height: 70vh;
        margin:0;
        padding:0;
    }

    .main-photo {
        background-image: url("/src/img/portrait.jpg");
        background-size: cover;
        background-position: top;
        width: inherit;
        height: inherit;
        position: absolute;
    }

    .intro {
        width: 30vw;
        height: inherit;
        margin-left: 10vmin;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }

    .main-buttons {
        display: flex;
        flex-direction: row;
    }

    .card {
        position: relative;
        background-color: white;
        right: -26vw;
        width: 17vw;
        height: 35vh;
        margin: 15vh 0;
        padding: 0;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;

        border-color: var(--primary);
        border-style: solid;
        border-width: 5px;
    }

    .hello {
        font-size: 7.5vmin;
        margin: 0;
        font-weight: bold;
        text-align: left;
    }

    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        border-color: var(--primary);
        border-width: 1.5px;
        height: 4vh;
        width: 15vmin;
        font-size: 2vmin;
    }

    .card-socials a img{
        width: 2vw;
    }
    
    .card-footer {
        background-color: var(--primary);
        position: absolute;
        width: inherit;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 5vh;
        bottom: 0;
    }

    .intro p {
        font-size: 1.2vw;
    }
}

.card-header {
    display: flex;
    height: 35vh;
    padding: 0 1vw;
    align-items: center;
    justify-content: center;
}

/* .portrait {
    background-image: url("/src/img/20240403_Javon Hickman_1009.jpg");
    background-position: center;
    background-size: cover;
} */

.card-socials {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.card-socials li {
    margin: 0 1vw;
}

.intro a {
    color: var(--primary);
    font-weight: bold;
    text-decoration: underline;
}

.card-socials a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.portrait {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: inherit;
    padding:0;
}

.portrait img {
    width: 10vw;
    height: 10vw;
    object-fit: cover;
    border-radius: 100%;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

h1 {
    font-size: 5vmin;
    margin: 0;
    font-weight: 100;
}

h5 {
    margin: 2vmin 0;
    font-weight: normal;
}

.resume-button button {
    background-color: var(--primary);
    color: white;
    margin: .8vh .5vw .8vh 0;
    border-style: solid;

    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
}

.resume-button button:hover, .resume-button button:focus, .resume-button button:active,
.projects-button button:hover, .projects-button button:focus, .projects-button button:active {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.projects-button button {
    background-color: white;
    color: var(--primary);
    margin: .8vh 0 .8vh .5vw;
    border-style: solid;

    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
}

footer {
    width: 100vw;
    display: block;
    position: absolute;
    flex-direction: row;
    top: 85vh;
    height: 10vh;
}

.footer-contact {
    position: absolute;
    display: flex;
    flex-direction: row;
    right: 0;
    bottom: -8vh;
    margin: 4vh 2vw;
    padding: 0;
}

footer li {
    text-align: center;
    font-size: calc(5px + 1vmin);
    margin: 0 1vmin 0 1vmin;
    list-style: none;
}

footer li img {
    margin: .2vw;
}

.footer-updated {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    font-size: 1vmin;
    margin: 2vh 2.5vw;
    width:50%;
    bottom: -5vh;
}

.attribution-home > a {
    color: black;
}
