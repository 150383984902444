nav {
    position: absolute;
    top: 0;
    width: 100vw;
    display: flex;
    flex-direction: row;
}

nav ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    font-size: 2vh;
}

nav li {
    margin: 0 1vmin 0 1vmin;
    list-style: none;
}

.link{
    color: black;
    text-decoration: none;
    font-size: calc(8px + 1vmin);
}

li > .active {
    color: var(--primary);
    text-decoration: none;
}

li > .inactive {
    color: black;
    text-decoration: none;
}

li > .inactive:hover {
    color: var(--primary);
    text-decoration: none;
}


nav .left {
    margin: 2vmin;
    display: flex;
    flex-direction: row;
}

nav .right {
    position: absolute;
    right: 0;
    margin: 2vmin;
}

.home-icon {
    background-color: var(--primary);
    padding: 1vh;
    margin: 1vh;
    justify-content: center;
    width: 0;
    height: 0;
}

.menu {
    display: flex;
    flex-direction: column;
}

.hamburger-menu {
    margin: 1vh;
    right: 0;
    position: absolute;
}

.menu-mobile {
    margin-top: 4vh;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 2vh;
    font-size: 2.5vw;
    background-color: white;
    z-index: 3;
}

.menu-mobile hr {
    /* border-color: var(--primary); */
    margin: 1vh;
    width: 100%;
}

.menu-mobile .link{
    font-size: 2.5vw;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h6 {
    font-size: 2vmin;
    font-weight: normal;
    margin: 0;
}

#dropdown-button {
    color: inherit;
    border: none;
    background: none;
    font-size: 2vh;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    height: 2.7vh;
    width: 11vh;
    align-items: center;
}

#dropdown-button:hover {
    color: var(--primary);
    text-decoration: none;
}
