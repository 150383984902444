.static-page {
    height: 100%;
}

.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 0 5vh 0;
}

h1 {
    text-align: center;
}

@media only screen and (orientation: portrait) {
    .about-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
        padding: 5vw;
        margin: 2.5vh;
        position: relative;
        width: 80vw;
        font-size: 2.2vw;
    }

    .about-photo {
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: right;
        align-items: right;
        width: 75vw;
        height: 40vh;
        margin: 4vh;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    }

    footer {
        display: none;
    }
}

@media only screen and (orientation: landscape) {
    .about-card {
        display: flex;
        flex-direction: row;
        background-color: white;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
        padding: 2vw;
        margin: 2.5vh;
        position: relative;
        width: 80vw;
        font-size: 1.2vw;
    }

    .about-photo {
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: right;
        align-items: right;
        width: 200%;
        margin-left: 2vw;
    }
}

.about-photo.professional {
    background-image: url("/src/img/STARS_Conference.jpg");
}

.about-photo.professional2 {
    background-image: url("/src/img/presentation2.jpg");
}

